<template>
    <v-container>
        <loading v-model="loading" />
        <!-------------- TITOLO
                    ----------------->
        <v-row>
            <v-col cols="12">
                <h1 class="pt-16 text-center">
                    <span v-show="!editing && !newz">{{ $t('informazioni') }} </span>
                    <span v-show="newz">{{ $t('creazione') }} </span>
                    <span v-show="editing && !newz">{{ $t('modifica') }} </span>
                    {{ $tc('zona', 1) }}
                </h1>
            </v-col>
        </v-row>
        <!--------------  creazione/modifica agente
                       ----------------->

        <v-card class="overflow-hidden" color="primary" dark>
            <v-toolbar color="primary darken-1">
                <a @click="$router.go(-1)">
                    <v-icon class="mr-3 ml-4">arrow_back</v-icon>
                </a>

                <v-spacer></v-spacer>
                <v-btn color="green ml-2" fab small @click="duplicate()" v-show="!editing">
                    <v-icon>content_copy</v-icon>
                </v-btn>
                <v-btn color="green ml-2" fab small @click="exportZone()" v-show="!editing">
                    <v-icon>download</v-icon>
                </v-btn>
                <v-btn color="green ml-2" fab small @click="Edit(true)" v-show="!editing">
                    <v-icon>edit</v-icon>
                </v-btn>
                <v-btn
                    color="red "
                    class="ml-2"
                    fab
                    small
                    @click="showDeleteConfirmation = true"
                    v-show="!editing"
                    v-if="$permission($auth.utente.role.name, 'deleteZone')"
                >
                    <v-icon>delete</v-icon>
                </v-btn>
                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="success" v-show="newz" @click="Create" :disabled="loading"> {{ $t('crea') }} </v-btn>
                    <v-btn color="success" v-show="editing && !newz" :disabled="loading" @click="Save"> {{ $t('salva') }} </v-btn>
                    <v-btn color="error" v-show="editing && !newz" @click="Edit(false)" :disabled="loading"> {{ $t('annulla') }} </v-btn>
                </v-card-actions>
            </v-toolbar>
            <v-card-text>
                <v-row class="pa-4">
                    <v-col cols="12" sm="6">
                        <v-text-field v-model="Zona.Name" :label="$t('nome')" outlined :readonly="!editing"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-autocomplete
                            v-model="selectionCategories"
                            :label="$t('categoria')"
                            :items="nameCategories"
                            item-text="name"
                            return-object
                            outlined
                            multiple
                            chips
                            clearable
                            deletable-chips
                            :readonly="!editing"
                        >
                        </v-autocomplete>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="8" class="map mapZindex">
                        <l-map style="width: 100%; height: 100%" :zoom="zoom" :center="center" :options="options" ref="myMap" @ready="mapReady()">
                            <l-control-layers position="topright"></l-control-layers>
                            <l-control position="topleft">
                                <v-btn x-small class="button_location" @click="getCoordinate()"> <v-icon size="22" class="">my_location</v-icon></v-btn>
                            </l-control>
                            <l-tile-layer
                                v-for="tileProvider in tileProviders"
                                :key="tileProvider.name"
                                :name="tileProvider.name"
                                :visible="tileProvider.visible"
                                :url="tileProvider.url"
                                :attribution="tileProvider.attribution"
                                layer-type="base"
                            />

                            <l-marker
                                v-for="marker in cityMarkers"
                                :lat-lng="marker.coordinate"
                                :key="marker.id"
                                :icon="getIcon(marker)"
                                @mouseover="mouseOverCity"
                                @mouseout="mouseOutCity"
                            >
                                <l-popup>
                                    <div @mouseover="active = true" @mouseout="active = false">
                                        {{ getCityName(marker.id) }}
                                    </div>
                                </l-popup>
                            </l-marker>
                        </l-map>
                    </v-col>
                    <v-col cols="12" md="4" v-show="editing">
                        <!-- V-CARD MODIFICA REGOLE -->
                        <v-card max-width="500" height="100%" class="mx-auto">
                            <v-app-bar dark color="#black ">
                                <v-card-title :class="$vuetify.breakpoint.smAndUp ? 'text-h5' : 'text-h6'">
                                    {{ editingRule ? $t('modifica') : $t('aggiungi') }} {{ $tc('regola', 2) }}:</v-card-title
                                >

                                <v-spacer></v-spacer>
                            </v-app-bar>

                            <v-card-text>
                                <v-text-field v-model="ruletmp.name" :label="$t('nome-regola')" class="" outlined :readonly="!editing"></v-text-field>

                                <v-autocomplete
                                    v-model="ruletmp.prov"
                                    :items="Province"
                                    :label="$tc('provincia', 1)"
                                    item-text="name"
                                    return-object
                                    outlined
                                    clearable
                                    @change="updateCities"
                                ></v-autocomplete>
                                <v-autocomplete
                                    v-if="tmpProv"
                                    v-model="ruletmp.cities"
                                    :label="$t('citta')"
                                    :items="Citta"
                                    item-text="name"
                                    return-object
                                    outlined
                                    multiple
                                    chips
                                    clearable
                                    deletable-chips
                                    @change="updateCaps()"
                                >
                                    <template v-slot:selection="{ index }">
                                        <span v-if="index === 0" class="mr-1 text-capitalize">{{ ruletmp.cities.length }} {{ $tc('selezionato', 2) }}</span>
                                    </template>
                                    <template v-slot:prepend-item>
                                        <v-list-item ripple @click="toggleCities">
                                            <v-list-item-content>
                                                <v-list-item-title>{{ allSelection ? $t('deseleziona-tutto') : $t('seleziona-tutto') }} </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider />
                                    </template>
                                </v-autocomplete>
                                <v-autocomplete
                                    v-if="tmpProv && tmpCit.length > 0 && Cap.length"
                                    v-model="ruletmp.caps"
                                    :label="$t('cap')"
                                    :items="Cap"
                                    outlined
                                    multiple
                                    chips
                                    clearable
                                    deletable-chips
                                    @change="selectCityLeads()"
                                >
                                    <template v-slot:selection="{ index }">
                                        <span v-if="index === 0" class="mr-1 text-capitalize">{{ ruletmp.caps.length }} {{ $tc('selezionato', 2) }}</span>
                                    </template>
                                    <template v-slot:prepend-item>
                                        <v-list-item ripple @click="toggleCaps">
                                            <v-list-item-content>
                                                <v-list-item-title>{{ allSelection ? $t('deseleziona-tutto') : $t('seleziona-tutto') }} </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider />
                                    </template>
                                </v-autocomplete>
                                <div class="mb-5" max-width="auto">
                                    <v-chip class="ma-1" color="white" outlined pill>
                                        {{ $tc('lead', 2) }}: {{ ruletmp.count }} {{ $t('dentale') }}: {{ ruletmp.dental }}
                                        <v-icon v-if="ruletmp.count == null && ruletmp.dental == null">hourglass_top</v-icon>
                                    </v-chip>
                                </div>
                                <v-btn color="success" v-show="editing" :disabled="!ruletmp.cities.length" @click="addRule"
                                    >{{ editingRule ? $t('modifica') : $t('aggiungi') }} {{ $tc('regola', 1) }}
                                </v-btn>

                                <v-btn class="ml-3" color="error" :disabled="!ruletmp.cities.length" v-show="editingRule" @click="cancelChanges">{{
                                    $t('annulla-modifiche')
                                }}</v-btn>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <!-- V-CARD LISTA REGOLE -->

                    <v-col :cols="12" :md="editing ? 12 : 4">
                        <v-card max-width="auto" class="mx-auto">
                            <v-app-bar dark color="#black " class="text-capitalize">
                                <v-card-title :class="$vuetify.breakpoint.smAndUp ? 'text-h5' : 'text-h6'">{{ $tc('regola', 2) }}:</v-card-title>
                                <v-spacer></v-spacer>
                                <v-chip class="ma-1" color="white" outlined pill>
                                    {{ $tc('lead', 2) }}: {{ leads.count }} {{ $t('dentale') }}: {{ leads.dental }}
                                </v-chip>
                            </v-app-bar>
                            <v-container>
                                <v-row dense>
                                    <v-col cols="12">
                                        <v-card class="mb-5" color="#385F73" dark v-for="(rule, index) in Zona.Rules" :key="index">
                                            <v-card-title class="text-h5">
                                                <span class="text-h5 text-capitalize" v-show="rule.name">{{ $t('nome-regola') }}: {{ rule.name }} <br /></span>
                                                <v-chip class="ma-1" color="white" outlined pill>
                                                    {{ $tc('lead', 2) }}: {{ rule.count }} {{ $t('dentale') }}: {{ rule.dental }}
                                                    <v-icon v-if="rule.count == null">hourglass_top</v-icon>
                                                </v-chip>
                                            </v-card-title>
                                            <v-card-text color="white" class="text-h5 text-capitalize">
                                                {{ $tc('provincia', 1) }}: {{ rule.prov.name }}
                                            </v-card-text>
                                            <v-expansion-panels>
                                                <v-expansion-panel>
                                                    <v-expansion-panel-header color="#385F73" class="text-h5"> {{ $t('citta') }}</v-expansion-panel-header>
                                                    <v-expansion-panel-content>
                                                        {{ rule.cities.map((x) => x.name).join(' / ') }}
                                                    </v-expansion-panel-content>
                                                </v-expansion-panel>
                                            </v-expansion-panels>
                                            <v-expansion-panels v-show="rule.caps.length">
                                                <v-expansion-panel>
                                                    <v-expansion-panel-header color="#385F73" class="text-h5"> {{ $t('cap') }}</v-expansion-panel-header>
                                                    <v-expansion-panel-content> {{ rule.caps.join(' / ') }} </v-expansion-panel-content>
                                                </v-expansion-panel>
                                            </v-expansion-panels>

                                            <v-card-actions>
                                                <v-btn color="primary" v-show="editing" @click="editRule(index)">{{ $t('modifica') }}</v-btn>
                                                <v-btn v-show="editing" class="ma-3" color="error" @click="openDialog(index)">{{ $t('elimina') }}</v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn color="success" v-show="newz" @click="Create" :disabled="loading"> {{ $t('crea') }} </v-btn>
                <v-btn color="success" v-show="editing && !newz" :disabled="loading" @click="Save"> {{ $t('salva') }} </v-btn>
                <v-btn color="error" v-show="editing && !newz" @click="Edit(false)" :disabled="loading"> {{ $t('annulla') }} </v-btn>
            </v-card-actions>
        </v-card>
        <!-- messaggio conferma eliminazione regola  -->

        <v-dialog v-model="dialog" width="500" transition="dialog-bottom-transition">
            <v-card>
                <v-card-title class="text-h5 grey justify-center lighten-2"> {{ $t('confermi-') }} </v-card-title>

                <v-card-text class="mt-6 text-center"> {{ $t('sei-sicuro-di-voler-eliminare-la-regola') }} </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-btn text @click="cancel">{{ $t('annulla') }}</v-btn>
                    <v-spacer></v-spacer>

                    <v-btn text color="red" @click="deleteRule()">{{ $t('elimina') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="showDeleteConfirmation" max-width="500">
            <v-card outlined>
                <v-card-title class="text-h5 grey justify-center lighten-2"> {{ $t('confermi-') }} </v-card-title>

                <v-card-text class="text-center mt-6"> {{ $t('sei-sicuro-di-voler-eliminare-la-zona') }} </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-btn text @click="showDeleteConfirmation = false">{{ $t('annulla') }}</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="red" text @click="Delete()">{{ $t('elimina') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import loading from '@/components/loading.vue'
import L from 'leaflet'
import 'leaflet-routing-machine'
import 'leaflet/dist/leaflet.css'
import { latLng } from 'leaflet'
import { LMap, LTileLayer, LMarker, LPopup, LControlLayers, LControl } from 'vue2-leaflet'
import { Icon } from 'leaflet'

delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})

export default {
    components: {
        loading,
        LMap,
        LTileLayer,
        LMarker,
        LPopup,
        LControlLayers,
        LControl
    },
    name: 'Zone',
    data() {
        return {
            Zona: {
                id: null,
                Name: null,
                Rules: []
            },
            ruletmp: { name: null, prov: null, cities: [], caps: [], count: null, dental: null },
            Province: [],
            Citta: [],
            Cap: [],
            editing: null,
            newz: null,
            loading: false,
            ZonaOriginal: {},
            allCapSelected: false,
            dialog: false,
            removeIndex: -1,
            editingRule: false,
            editingRuleIndex: -1,
            modified: false,
            showDeleteConfirmation: false,
            zoom: 14,
            center: [41.9028, 12.4964],
            tileProviders: [
                {
                    name: 'OpenStreetMap',
                    visible: true,
                    attribution: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
                    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                },
                {
                    name: 'OpenTopoMap',
                    visible: false,
                    url: 'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png',
                    attribution:
                        'Map data: &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)'
                },
                {
                    name: 'Satellite',
                    visible: false,
                    url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
                    attribution:
                        'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
                }
            ],
            options: {
                minZoom: 7,
                maxZoom: 20
            },

            selectedMarker: null,
            titleMarker: null,
            mapInfo: {
                east: null,
                west: null,
                north: null,
                south: null,
                center: null
            },
            map: null,
            route: null,
            currLocation: { lat: 0, lng: 0 },

            icongreen: L.icon({
                iconUrl: require('@/assets/marker/marker_green.png'),
                iconRetinaUrl: require('@/assets/marker/marker_green_2x.png'),
                shadowUrl: require('@/assets/marker/marker-shadow.png'),
                iconSize: [26, 41],
                iconAnchor: [13, 41]
            }),
            iconblue: L.icon({
                iconUrl: require('@/assets/marker/marker_blue.png'),
                iconRetinaUrl: require('@/assets/marker/marker_blue_2x.png'),
                shadowUrl: require('@/assets/marker/marker-shadow.png'),
                iconSize: [26, 41],
                iconAnchor: [13, 41]
            }),
            iconred: L.icon({
                iconUrl: require('@/assets/marker/marker_red.png'),
                iconRetinaUrl: require('@/assets/marker/marker_red_2x.png'),
                shadowUrl: require('@/assets/marker/marker-shadow.png'),
                iconSize: [26, 41],
                iconAnchor: [13, 41]
            }),
            iconyellow: L.icon({
                iconUrl: require('@/assets/marker/marker_yellow.png'),
                iconRetinaUrl: require('@/assets/marker/marker_yellow_2x.png'),
                shadowUrl: require('@/assets/marker/marker-shadow.png'),
                iconSize: [26, 41],
                iconAnchor: [13, 41]
            }),

            cityMarkers: [],
            allZones: [],
            nameCategories: [],
            selectionCategories: null,
            leads: { count: 0, dental: 0 },
            active: false
        }
    },
    computed: {
        tmpProv() {
            return this.ruletmp.prov
        },
        tmpCit() {
            return this.ruletmp.cities
        },
        rules() {
            return this.Zona.Rules
        },
        isFormValid() {
            return !!this.Zona.Name && this.Zona.Rules.length > 0
        },
        allSelection: {
            get() {
                return (
                    this.Citta.length > 0 && this.ruletmp.cities.length === this.Citta.length,
                    this.Cap.length > 0 && this.ruletmp.caps.length === this.Cap.length
                )
            },
            set(valore) {
                if (valore) {
                    this.ruletmp.cities = JSON.parse(JSON.stringify(this.Citta))
                } else {
                    this.ruletmp.cities = []
                }
            }
        }
    },
    watch: {
        selectionCategories: {
            async handler(newCategories) {
                if (!newCategories) return

                // Step 1: Aggiorna i conteggi globali se ci sono regole
                if (this.rules.length) {
                    await this.getNLeadsRules()

                    // Step 2: Aggiorna i conteggi per ogni regola
                    for (const rule of this.Zona.Rules) {
                        const counter = await this.getNLeadsRule(rule)
                        rule.count = counter.count
                        rule.dental = counter.dental
                    }
                }

                // Step 3: Aggiorna i conteggi della regola in modifica
                if (this.ruletmp.cities.length) {
                    await this.selectCityLeads()
                }
            },
            deep: true
        }
    },
    props: {
        pedit: { type: Boolean, default: false },
        pnew: { type: Boolean, default: false }
    },
    async created() {
        if (!this.$permission(this.$auth.utente.role.name, 'ZonePage')) {
            this.$router.push('/')
            return
        }
        this.newz = this.pnew
        if (this.newz) {
            this.Edit(true)
        }
        if (this.$route.params.id) {
            const doc = await this.$http
                .get(`zones/${this.$route.params.id}`, {
                    populate: []
                })
                .catch((err) => {
                    console.log('errore', err)
                })
            if (!doc) {
                this.$router.push('/')
                return
            }

            this.Zona = {
                id: doc.data.id,
                Name: doc.data.attributes.Name,
                Rules: doc.data.attributes.Rules
            }
            if (doc.data.attributes.selectedCategories) {
                this.selectionCategories = doc.data.attributes.selectedCategories.map((catName) => ({
                    name: catName
                }))
            }
            this.Zona.Rules.forEach(async (x) => {
                const counter = await this.getNLeadsRule(x)
                x.count = counter.count
                x.dental = counter.dental
            })

            if (this.pedit) {
                await this.Edit(true)
            }
        }
        const categories = await this.$http.get('categories')
        this.nameCategories = categories.data.map((x) => {
            return { name: x.attributes.name }
        })

        const p = await this.$http.get(`provinces`, { 'pagination[pageSize]': 200 })
        this.Province = p.data.map((x) => {
            return { id: x.id, name: x.attributes.Name, lat: x.attributes.latitude, lng: x.attributes.longitude }
        })

        await this.updateMarker()
    },
    methods: {
        //mousehover popup city
        mouseOverCity(event) {
            //triggerato evento quando hover
            const marker = event.target
            //apre il popoup
            if (!marker.isPopupOpen()) {
                marker.openPopup()
            }
            //tracciare stato
            this.active = true
        },
        // Aggiungiamo anche una funzione per gestire il mouseout
        mouseOutCity(event) {
            const marker = event.target

            // Chiudiamo il popup quando il mouse esce dal marker
            setTimeout(() => {
                // Controlliamo se il mouse è ancora fuori dall'area
                if (!this.active) {
                    marker.closePopup()
                }
            }, 1000) //trracciare stato

            this.active = false
        },
        getCityName(markerId) {
            for (const rule of this.Zona.Rules) {
                const city = rule.cities.find((c) => c.id === markerId)
                if (city) return city.name
            }
            return ''
        },
        async updateMarker() {
            if (this.Zona.Rules.length > 0 && !this.editing) {
                let Citta = []
                let ci = []
                for (const rule of this.Zona.Rules) {
                    let allCityIds = new Set()
                    rule.cities.forEach((city) => allCityIds.add(city.id))
                    let c = {}
                    let pa = 1
                    do {
                        c = await this.$http.get(`cities`, {
                            'populate': ['Province'],
                            'filters': {
                                ...([...allCityIds].length > 0 ? { id: { $in: [...allCityIds] } } : {})
                            },
                            'pagination[pageSize]': 200,
                            'pagination[page]': pa,
                            'sort': 'Name'
                        })

                        ci = ci.concat(c.data)
                        pa++
                    } while (c.meta.pagination.pageCount > c.meta.pagination.page)
                }

                for (const x of ci) {
                    if (x.attributes.latitude && x.attributes.longitude) {
                        Citta.push({
                            id: x.id,
                            name: x.attributes.Name,
                            used: this.allZones.some((z) => z.attributes.cities.data.some((zz) => zz.id == x.id)),
                            coordinate: latLng(x.attributes.latitude, x.attributes.longitude)
                        })
                    }
                }

                this.cityMarkers = Citta
                const prov = this.Province.find((x) => x.id == this.Zona.Rules[0].prov.id)
                this.map.setView([prov.lat, prov.lng], 8)
            }
        },
        async selectCityLeads() {
            try {
                const selectedCategories = this.selectionCategories?.map((cat) => cat.name) || []

                const counter = await this.getNLeadsRule({
                    ...this.ruletmp,
                    categories: selectedCategories
                })

                this.ruletmp.count = counter.count
                this.ruletmp.dental = counter.dental
            } catch (error) {
                console.error('Errore nel conteggio dei leads:', error)
                this.ruletmp.count = 0
                this.ruletmp.dental = 0
            }
        },
        getIcon(marker) {
            if (this.editing) {
                const selected = this.ruletmp.cities.some((x) => x.id == marker.id)

                if (marker.used) {
                    if (selected) {
                        return this.iconyellow
                    } else {
                        return this.iconred
                    }
                } else {
                    if (selected) {
                        return this.icongreen
                    } else {
                        return this.iconblue
                    }
                }
            } else {
                return this.icongreen
            }
        },
        selectCity(marker) {
            if (this.editing) {
                if (this.ruletmp.cities.find((x) => x.id == marker)) {
                    this.ruletmp.cities = this.ruletmp.cities.filter((x) => x.id != marker)
                } else {
                    this.ruletmp.cities.push(this.Citta.find((x) => x.id == marker))
                }
                this.selectCityLeads()
            }
        },

        addCityMarker() {
            // aggiungo marker di un certo colore
            let m = []
            let l2 = []
            if (this.editingRule) {
                let l1 = [...this.Zona.Rules]
                l1.splice(this.editingRuleIndex, 1)
                l2 = l1.map((x) => x.cities.map((y) => y.id)).flat()
            }
            this.Citta.forEach((c) => {
                if (c.lat && c.lng) {
                    m.push({
                        id: c.id,
                        used: c.used ? c.used : this.editingRule ? l2.includes(c.id) : c.used,
                        coordinate: latLng(c.lat, c.lng)
                    })
                }
            })

            this.cityMarkers = m
        },
        async getNLeadsRules() {
            try {
                const selectedCategories = this.selectionCategories?.map((cat) => cat.name) || []

                this.leads = await this.$http.post(`leadsByRules`, {
                    rules: this.rules,
                    categories: selectedCategories
                })
            } catch (error) {
                console.error('Errore nel recupero dei leads:', error)
                this.leads = { count: 0, dental: 0 }
            }
        },
        async getNLeadsRule(rule) {
            try {
                const selectedCategories = this.selectionCategories?.map((cat) => cat.name) || []

                return await this.$http.post(`leadsByRule`, {
                    ...rule,
                    categories: selectedCategories
                })
            } catch (error) {
                console.error('Errore nel recupero dei leads per la regola:', error)
                return { count: 0, dental: 0 }
            }
        },

        cancel() {
            this.dialog = false
        },
        async editRule(index) {
            this.editingRuleIndex = index
            this.editingRule = true
            let rule = this.Zona.Rules[index]
            this.ruletmp = JSON.parse(JSON.stringify(rule))
            await this.getCities(this.tmpProv)
            this.getCaps(this.tmpCit)
        },
        cancelChanges() {
            if (this.modified) {
                if (confirm(this.$t('sei-sicuro-di-voler-annullare-le-modifiche'))) {
                    if (this.editingRule) {
                        this.ruletmp = JSON.parse(JSON.stringify(this.Zona.Rules[this.editingRuleIndex]))
                        this.editingRule = false
                        this.editingRuleIndex = -1
                    } else {
                        this.Zona = JSON.parse(JSON.stringify(this.ZonaOriginal))
                    }
                    this.ruletmp.prov = null
                    this.ruletmp.cities = []
                    this.ruletmp.caps = []
                    this.modified = false
                    this.cityMarkers = []
                }
            } else {
                if (this.editingRule) {
                    this.ruletmp = JSON.parse(JSON.stringify(this.Zona.Rules[this.editingRuleIndex]))
                    this.editingRule = false
                    this.editingRuleIndex = -1
                } else {
                    this.Zona = JSON.parse(JSON.stringify(this.ZonaOriginal))
                }
                this.ruletmp.prov = null
                this.ruletmp.cities = []
                this.ruletmp.caps = []
                this.cityMarkers = []
            }
        },

        openDialog(index) {
            this.removeIndex = index
            this.dialog = true
        },
        deleteRule() {
            if (this.removeIndex > -1) {
                this.Zona.Rules.splice(this.removeIndex, 1)
                this.removeIndex = -1
            }
            this.dialog = false
        },
        async Edit(stato) {
            if (stato) {
                let filters = {}
                if (this.Zona.id) {
                    filters = { id: { $ne: this.Zona.id } }
                }
                let zone = []
                let z = {}
                let pa = 1
                do {
                    z = await this.$http.get(`zones`, {
                        'populate': ['cities'],
                        'filters': filters,
                        'pagination[pageSize]': 200,
                        'pagination[page]': pa
                    })
                    zone = zone.concat(z.data)
                    pa++
                } while (z.meta.pagination.pageCount > z.meta.pagination.page)
                this.allZones = zone

                this.ZonaOriginal = JSON.parse(JSON.stringify(this.Zona))
                this.cityMarkers = []
            } else {
                if (this.modified) {
                    if (confirm(this.$t('sei-sicuro-di-voler-annullare-le-modifiche'))) {
                        this.Zona = JSON.parse(JSON.stringify(this.ZonaOriginal))
                        this.modified = false
                    }
                } else {
                    this.Zona = JSON.parse(JSON.stringify(this.ZonaOriginal))
                }
            }
            this.editing = stato

            this.updateMarker()
        },
        async Delete() {
            try {
                // Effettua la chiamata HTTP per eliminare il lead
                await this.$http.delete(`zones/${this.Zona.id}`)

                // Chiudi il dialogo di conferma ed esegui altre azioni
                this.showDeleteConfirmation = false
                this.$router.push('/zones')
            } catch (error) {
                // Gestisci l'errore durante l'eliminazione del lead
                console.error(error)

                // Mostra una notifica di errore
                this.$notify({
                    group: 'notifica',
                    title: this.$t('errore'),
                    text: this.$t('si-e-verificato-un-errore-durante-leliminazione-della-zona'),
                    type: 'error'
                })
                this.showDeleteConfirmation = false
            }
        },
        async addRule() {
            this.ruletmp.cities.sort((a, b) => a.name.localeCompare(b.name))
            this.ruletmp.caps.sort((a, b) => a.localeCompare(b))
            if (this.editingRule) {
                this.Zona.Rules.splice(this.editingRuleIndex, 1, this.ruletmp)
                this.editingRule = false
                this.editingRuleIndex = -1
            } else {
                this.Zona.Rules.push({ ...this.ruletmp })
            }

            this.ruletmp = { prov: null, cities: [], caps: [], count: null, dental: null }
            this.modified = true
            this.cityMarkers = []
        },
        async updateCities() {
            if (!this.tmpProv) {
                this.Citta = []
                this.ruletmp.cities = []

                return
            }

            await this.getCities(this.tmpProv)
        },

        updateCaps() {
            if (!this.tmpCit.length) {
                this.Cap = []
                this.ruletmp.caps = []
                this.selectCityLeads()
                return
            }
            this.getCaps(this.tmpCit)
            this.selectCityLeads()
        },
        async getCities(prov, cityIds = []) {
            this.Citta = []
            let ci = []
            let c = {}
            let pa = 1
            do {
                c = await this.$http.get(`cities`, {
                    'populate': ['Province'],
                    'filters': {
                        Province: { id: { $eq: prov.id } },
                        ...(cityIds.length > 0 ? { id: { $in: cityIds } } : {})
                    },
                    'pagination[pageSize]': 200,
                    'pagination[page]': pa,
                    'sort': 'Name'
                })

                ci = ci.concat(c.data)
                pa++
            } while (c.meta.pagination.pageCount > c.meta.pagination.page)
            let cc = []

            for (const x of ci) {
                cc.push({
                    id: x.id,
                    name: x.attributes.Name,
                    cap: x.attributes.Cap,
                    lng: x.attributes.longitude,
                    lat: x.attributes.latitude,
                    used: this.allZones.some((z) => z.attributes.cities.data.some((zz) => zz.id == x.id))
                })
            }

            this.Citta = cc

            this.addCityMarker()
            this.map.setView([prov.lat, prov.lng], 8)
        },
        getCaps(cities) {
            const idcit = cities.map((x) => {
                return x.id
            })
            this.Cap = []
            let cap = new Set()
            this.Citta.forEach((x) => {
                if (!idcit.includes(x.id)) {
                    return
                }
                if (x.cap.length == 1) return
                x.cap.forEach((c) => {
                    cap.add(c)
                })
            })
            this.Cap = [...cap].sort()
            if (!this.editingRule) {
                this.ruletmp.caps = [...cap].sort()
            }
        },
        toggleCaps() {
            if (this.Cap.length == this.ruletmp.caps.length) {
                this.ruletmp.caps = []
                this.allCapSelected = false
            } else {
                this.ruletmp.caps = this.Cap.slice()
                this.allCapSelected = true
            }
            this.selectCityLeads()
        },
        toggleCities() {
            if (this.Citta.length == this.ruletmp.cities.length) {
                this.ruletmp.cities = []
            } else {
                this.ruletmp.cities = JSON.parse(JSON.stringify(this.Citta))
            }
            this.updateCaps()
        },
        async Save() {
            if (!this.isFormValid) {
                this.$notify({
                    group: 'notifica',
                    title: this.$t('errore'),
                    text: this.$t('il-nome-o-una-regola-non-sono-stati-inseriti'),
                    type: 'error'
                })
                return
            }
            try {
                if (this.loading) return
                this.loading = true

                const zone = await this.$http.put(`zones/${this.Zona.id}`, {
                    data: {
                        Name: this.Zona.Name,
                        Rules: this.Zona.Rules,
                        selectedCategories: this.selectionCategories?.map((cat) => cat.name) || []
                    }
                })

                //success
                this.$notify({
                    group: 'notifica',
                    title: this.$t('salvataggio'),
                    text: this.$t('salvataggio-avvenuto-con-successo'),
                    type: 'success'
                })
            } catch (error) {
                console.log(error)
                this.$notify({
                    group: 'notifica',
                    title: this.$t('salvataggio'),
                    text: this.$t('errore-nel-salvataggio'),
                    type: 'error'
                })
            } finally {
                this.editing = false
                this.loading = false
                this.updateMarker()
            }
        },
        async Create() {
            if (!this.isFormValid) {
                this.$notify({
                    group: 'notifica',
                    title: this.$t('errore'),
                    text: this.$t('il-nome-o-una-regola-non-sono-stati-inseriti'),
                    type: 'error'
                })
                return
            }
            try {
                if (this.loading) return

                this.loading = true

                const agent = await this.$http.post(`zones`, {
                    data: {
                        Name: this.Zona.Name,
                        Rules: this.Zona.Rules,
                        categories: this.selectionCategories?.map((cat) => cat.name) || [] // Aggiungi questa riga
                    }
                })

                //success
                this.$notify({
                    group: 'notifica',
                    title: this.$t('salvataggio'),
                    text: this.$t('salvataggio-avvenuto-con-successo'),
                    type: 'success'
                })
                this.$router.push('/zones')
            } catch (error) {
                console.log(error)
                this.$notify({
                    group: 'notifica',
                    title: this.$t('errore'),
                    text: this.$t('errore-nel-salvataggio'),
                    type: 'error'
                })
            } finally {
                this.loading = false
            }
        },
        exportZone() {
            let txt = `${this.$t('nome-zona')}: ${this.Zona.Name} (${this.leads})\n\n`

            this.Zona.Rules.forEach((rule, index) => {
                txt += `${this.$t('nome-regola')}: ${rule.name} (${rule.count})\n`
                txt += `${this.$tc('provincia', 1)}: ${rule.prov.name} \n`
                txt += `${this.$t('citta')}: \n`
                rule.cities.forEach((y) => {
                    txt += `${y.name} \n`
                })
                if (rule.caps.length) {
                    txt += `\nCAP: \n`
                    rule.caps.forEach((y) => {
                        txt += `${y} \n`
                    })
                }
                txt += `\n`
            })

            const anchor = document.createElement('a')
            anchor.href = 'data:text/plain;charset=utf-8,' + encodeURIComponent(txt)
            anchor.target = '_blank'
            anchor.download = `zona-${this.Zona.Name}.txt`
            anchor.click()
        },
        duplicate() {
            this.$router.push({
                path: '/zone/new',
                replace: true
            })
            this.newz = true
            this.Zona.id = null
            this.Zona.Name = null
            this.editing = true
        },
        async mapReady() {
            this.map = this.$refs.myMap.mapObject
            this.mapInfo = {
                east: this.map.getBounds().getEast(),
                west: this.map.getBounds().getWest(),
                north: this.map.getBounds().getNorth(),
                south: this.map.getBounds().getSouth(),
                center: this.map.getCenter()
            }

            var self = this
            this.map.on('moveend', function onDragEnd() {
                if (self.autozoom) {
                    self.autozoom = false
                    return
                }
                self.mapInfo = {
                    east: self.map.getBounds().getEast(),
                    west: self.map.getBounds().getWest(),
                    north: self.map.getBounds().getNorth(),
                    south: self.map.getBounds().getSouth(),
                    center: self.map.getCenter()
                }
            })
        },

        async addRuleMap(marker) {
            this.ruletmp.prov = marker.details.province
            await this.updateCities()
            this.ruletmp.cities = [marker.details.city]
        }
    }
}
</script>
<style>
.v-list-item__content {
    align-items: center;
    align-self: center;
    display: block !important;
    flex-wrap: wrap;
    flex: 1 1;
    overflow: hidden;
    padding: 12px 0;
}
.map {
    height: 600px;
}
.v-icon.v-icon.v-icon--link {
    cursor: pointer;
    outline: none;
    display: none;
}
</style>
